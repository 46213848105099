<template>
    <section class="login_page toolbar_main">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 mx-auto">
                    <div class="widget">
                        <div class="login-modal-right">
                            <div class="tab-content">
                                <div class="tab-pane active" role="tabpanel">
                                    <h5 class="heading-design-h5"> <i class="fa fa-lock"></i>  Restablecer contraseña </h5>

                                    <p class="pb-1 mb-0" style=" font-weight: initial;line-height: normal; text-align:justify;">
                                        Ingresa tu correo electrónico y  te enviaremos tus nuevas credenciales.
                                    </p>
                                    <fieldset class="form-group">
                                        <strong>Correo electronico</strong>
                                        <input type="text" class="form-control" v-model="formAccount.nick" placeholder="cliente@luatelclaro.pe">
                                    </fieldset>
                                    
                                    <fieldset class="form-group">
                                        <button type="button" class="btn btn-lg btn-theme-round cursor-pointer btn-block" @click="recuperar_clave">Restablecer contraseña</button>
                                    </fieldset>

                                    <fieldset class="form-group" v-if="formAccount.error.length>0">
                                        <ul class="list-group">
                                            <li class="list-group-item list-group-item-action list-group-item-danger" v-for="(val, key) in formAccount.error" :key="key">
                                                {{val}}
                                            </li>
                                        </ul>
                                    </fieldset>

                                     <fieldset class="form-group mb-0">
                                        <div class="alert mt-0 p-2 mb-0 alert-success" v-if="formAccount.msgSuccess!=''">
                                            {{formAccount.msgSuccess}}
                                            <p class="font-14 mb-0" >
                                                La página se recargará dentro de {{secondsReload}} segundos.
                                            </p>
                                        </div>

                                        <div class="alert mt-0 p-2 mb-0 alert-info">
                                            <p class="font-14 mb-0">
                                                Haga clic <a :href="'/tienda/'+sucursal_seleccionada">aquí</a> para ir al inicio <i class="icofont icofont-ui-home"></i>
                                            </p>
                                        </div>
                                     </fieldset>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    export default {
        name: 'recuperarCuenta',
        computed:{
            ...mapState(['empresa', 'sucursal_seleccionada']),
        },
        created(){
            this.loadObjSucursalSeleccionada();
            this.loadObjPerfilUsuario();
        },
        mounted(){
            document.title = "Recuperar Clave | "+((this.empresa.abreviatura)?this.empresa.abreviatura:"-");
        },
        data(){
            return{
                formAccount:{
                    nick:"",
                    error:[],
                    msgSuccess:"",
                },
                secondsReload: 10,
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjPerfilUsuario', 'loadObjSucursalSeleccionada']),
            recuperar_clave(){
                let selfs = this;
                let formData = new FormData();
                    formData.append("nick", this.formAccount.nick);

                selfs.setLoading(true);
                selfs.$http.post('tiendaVirtual/recuperar_clave', formData).then((res)=>{
                    if(res.data.status){
                        selfs.formAccount.error = [];
                        selfs.formAccount.msgSuccess = res.data.msg;
                        let time_seconds = this.secondsReload*1000;

                        setInterval(()=>{
                            time_seconds = time_seconds - 1000;
                            this.secondsReload = time_seconds/1000;
                        }, 1000);

                        setTimeout(()=>{                            
                            location.reload();
                        }, time_seconds);
                    }
                }).catch(function (e) {
                    if(e && e.response && e.response.status==422){ //Errores de Validacion
                        const mapErrors ={};
                        selfs.formAccount.error = [];
                        for (let field of Object.keys(e.response.data.errors)) {
                            mapErrors[field]=e.response.data.errors[field][0];

                            selfs.formAccount.error.push(mapErrors[field]);
                        }
                    }
                }).finally(()=>{
                    selfs.setLoading(false);
                });
            }
        }
    }
</script>
